import { get, post } from './base/request.js'


/**
 * @func 获取category数据
 *
 * @param {int} page 显示页号
 * @param {dict} filter { media_type, country, year, genre, order_by }
 * @return {[Object]} 电影对象列表
 */
export function fetchCategoryDataService(page, filter, callback, error) {

    var url = "/category/" + page

    get(url, filter)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}
