<template>
    <div class="filter-tag">
        <span style="margin-right: 8px">{{ filters.filter_display_name }}</span>
        <template v-for="tag in filters.filter_values">
            <a-checkable-tag
                :key="tag"
                :checked="selectedTags.indexOf(tag) > -1"
                @change="checked => handleChange(tag, checked)"
            >
                {{ tag }}
            </a-checkable-tag>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CategoryFilterTags',
    data() {
        return {
            checked1: false,
            checked2: false,
            checked3: false,
            selectedTags: [],
        };
    },
    props: ["filters","selectedFilters"],
    methods: {
        handleChange(tag, checked) {
            /**
            * @func 标签状态修改
            *
            * @param {String} tag 标签
            * @param {Boolean} checked 状态
            */

            const { selectedTags } = this;
            this.selectedTags = [tag]
            this.selectedFilters[this.filters.filter_name] = tag
            this.$emit('tag-click',this.selectedFilters)

        },
    },
    mounted() {
        if(this.filters.filter_name == "media_type"){
            this.selectedTags = ["电影"]
        }
        else if(this.filters.filter_name == "order_by"){
            this.selectedTags = ["最近更新"]
        }
        else{
            this.selectedTags = ["全部"]
        }

        // console.log(this.filters.filter_name)
        // console.log()
        if (this.$route.query[this.filters.filter_name]){
            this.selectedTags = [this.$route.query[this.filters.filter_name]]
        }

    }
};
</script>
