<template>
    <div id="category-page">
        <section class="ant-layout">
            <header class="ant-layout-header">
                <Head></Head>
            </header>
            <main class="ant-layout-content main-section" >
                <div id="category-filter-tags" class="category-filter-tags">
                    <CategoryFilterTags v-for="filters in allFilters" :key="filters.filter_name" :filters="filters" :selectedFilters="selectedFilters" @tag-click="tagClickFetchNewData"></CategoryFilterTags>
                </div>
                <div v-if="categoryData">
                    <a-spin tip="loading..." size="large" :spinning="spaining">
                        <a-row :gutter="[8,8]" v-for="row in totalRow" :key="row">
                            <a-col
                                :span="4"
                                v-for="column in calcColumnByRow(row)"
                                :key="column"
                            >
                                <div class="movie-cell">
                                    <MovieThumbView
                                        :movieData="getMoiveByRowAndColumn(row, column)"
                                        @clickMovie="clickMovie"
                                    >
                                    </MovieThumbView>
                                    <div style="margin-top:10px">
                                        <p class="title" align="left">
                                            {{ getMoiveByRowAndColumn(row, column).title }}
                                        </p>
                                        <p class="sub-title" align="left">
                                            {{ getMoiveByRowAndColumn(row, column).originaltitle }}
                                        </p>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                    </a-spin>
                </div>
            </main>
            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>
        </section>
    </div>
</template>

<script>

import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'
import CategoryFilterTags from '@/components/category/CategoryFilterTags'
import MovieThumbView from '@/components/common/MovieThumbView'

import { fetchCategoryDataService } from '@/services/categoryService.js'


export default {
    name: 'CategoryPage',
    head: {
        title: "电影分类 - 熊猫侠",
        meta:[
            {
                name: 'description',
                content: '熊猫侠(www.pandaman.tv), 致力于打造海外华人最大的在线视频平台, 以高清的视频内容，流畅的视频体验.',
            },
            {
                name: 'keywords',
                content: '熊猫侠,视频,视频分享,视频搜索,视频播放,视频社区,海外视频,电影,电视剧,真人秀,美剧,动漫,综艺,华人,影院',
            }
        ]
    },
    data() {
        return {
            page: 1,
            // 单行显示数量
            maxColumnOfRow: 6,
            categoryData: null,
            selectedFilters: {},
            allFilters: null,
            filter: null,
            // 是否显示loading
            spaining: false,
        }
    },
    components:{
        Head,
        Foot,
        CategoryFilterTags,
        MovieThumbView,
    },
    computed: {
        // 计算属性的 getter
        totalRow: function () {
            // * 计算当先数据可显示行数
            if (this.categoryData.length) {
                var total = Math.ceil(this.categoryData.length / this.maxColumnOfRow)
                return total
            }
            return 0
        }
    },
    watch: {
        // 监控 Route 变化,
        // URL 变化 需要重置页面 pageID
        $route(to, from) {
            console.log(this.$route.query)
            this.page = 1
        }
    },
    methods: {
        /**
        * @func 电影点击响应
        *
        * @param {object} selectMovie: 选中电影object
        */
        clickMovie(selectedMovie) {
            window.removeEventListener('scroll', this.scrollBottom)
            let routeData = this.$router.resolve({
                name: 'Play',
                params: {
                    uniqueId: selectedMovie.uniqueId
                }
            });
            window.open(routeData.href, '_blank');
            // this.$router.push({
            //     name: 'Play',
            //     params: {
            //         uniqueId: selectedMovie.uniqueId
            //     }
            // });
        },
        /**
        * @func 从头获取电影数据
        *
        * @param {object} selectedFilters: 当前选中的filters
        */
        fetchNewData(selectedFilters) {
            // console.log('fetttttttttch nnnnnnnnnew data');
            // console.log(selectedFilters)
            this.spaining = true
            fetchCategoryDataService(1, selectedFilters, response => {
                this.spaining = false
                // console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbb')
                // console.log(selectedFilters)
                this.categoryData = response.data.list
                // console.log(this.categoryData)
                this.allFilters = response.data.all_filter
                this.selectedFilters = response.data.select_filter
            }, error => {
                // 异常处理
            })
        },
        /**
        * @func 从头获取电影数据
        *
        * @param {object} selectedFilters: 当前选中的filters
        */
        tagClickFetchNewData(selectedFilters) {
            this.spaining = true
            const query = {}
            for (const [key, value] of Object.entries(selectedFilters)){
                // console.log(key,value);
                query[key] = value;
            }
            this.$router.push({
                name: 'Category',
                query: query
            });
            fetchCategoryDataService(1, selectedFilters, response => {
                this.spaining = false
                // console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbb')
                // console.log(selectedFilters)
                this.categoryData = response.data.list
                // console.log(this.categoryData)
                this.allFilters = response.data.all_filter
                this.selectedFilters = response.data.select_filter
            }, error => {
                // 异常处理
            })
        },
        /**
         * @func 获取更多电影数据
         */
        fetchMoreData() {
            fetchCategoryDataService(this.page, this.selectedFilters, response => {
                this.categoryData = this.categoryData.concat(response.data.list)
                console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
                console.log(this.page)
                // console.log(this.categoryData.length)
                this.$forceUpdate()
            }, error => {
                this.tips = "连接失败， 请稍后重试"
                this.REQUIRE = true
            })
        },
        /**
         * @func 事件监听: 页面滚动到底部( 响应加载更多 )
         */
        scrollBottomListener() {
            // console.log('页面滚动ing...')
            // 滚动到页面底部时，请求前一天的文章内容
            // 下面这句主要是获取网页的总高度
            // 主要是考虑兼容性所以把Ie支持的documentElement也写了
            // 这个方法至少支持IE8
            var htmlHeight = document.body.scrollHeight || document.documentElement.scrollHeight;
            // clientHeight是网页在浏览器中的可视高度，
            var clientHeight = document.body.clientHeight || document.documentElement.clientHeight;
            //scrollTop是浏览器滚动条的top位置，
            var scrollTop = document.body.scrollTopi || document.documentElement.scrollTop;
            //通过判断滚动条的top位置与可视网页之和与整个网页的高度是否相等来决定是否到达底部
            if(scrollTop + clientHeight >= htmlHeight - 150) {
                // 事件锁
                if(!this.timer) {
                    // 事件锁死
                    this.timer = true
                    let that = this
                    // 延迟 800ms 后解锁
                    setTimeout(() => {
                        that.timer = false
                    }, 200);
                    console.log('页面已经到底部！！！！')
                    this.page += 1
                    // 请求的数据未加载完成时，滚动到底部不再请求前一天的数据
                    this.REQUIRE = false;
                    this.loading = true;
                    this.tips = '努力加载中...';
                    this.fetchMoreData()
                }
            }
        },
        /**
        * @func 获取数组内电影数据 by row, column
        *
        * @param {Int} row: 行数
        * @param {Int} column: 列数
        * @returns {Object} 电影数据
        */
        getMoiveByRowAndColumn(row, column) {
            var tmp = (row - 1) * this.maxColumnOfRow
            return this.categoryData[tmp + column - 1]
        },
        /**
        * @func calc: row 包含多少列
        *
        * @param {Int} row: 行数
        * @return {Int}: 当前行所显示列数
        */
        calcColumnByRow(row) {
            // 非最后一行返回最大显示数
            if (row < this.totalRow) {
                return this.maxColumnOfRow
            }
            var tmp = this.categoryData.length - (this.maxColumnOfRow * (this.totalRow - 1))
            return tmp
        }
    },
    mounted () {
        // 页面添加滚动监听
        window.addEventListener('scroll', this.scrollBottomListener)
        // 从url 设置selectedfilter
        for (const [key, value] of Object.entries(this.$route.query)){
            this.selectedFilters[key] = value
        }
        if(Object.keys(this.selectedFilters).length == 0){
            this.fetchNewData(null)
        }
        else {
            // 页面加载时, 异步请求category数据
            this.fetchNewData(this.selectedFilters)
        }
    },
    destroyed () {
        // 页面销毁, 删除监听
        window.removeEventListener('scroll', this.scrollBottomListener)
    }
}

</script>

<style>

.movie-cell .title {
    font-size: 16px;
    font-weight: 700;
    font-family: PingFangdSC;
    /* font-family: "qiqi"; */
    margin-bottom: 1px;
}

.movie-cell .sub-title {
    font-size: 14px;
    font-weight: 500;
    font-family: PingFangdSC;
}

.movie-cell:hover p {
    color: #228efc;
}

</style>
